<template>
  <div>
    <!-- <popu-action-select v-model="dataForm.unemployment_cycle" name="unemployment_cycle" placeholder="baseInfo.unemployed" :data-list="this.$t('baseInfo.unemployedList')" :is-focus-point.sync="isFocusPoint" /> -->
  </div>
</template>

<script>

export default {
  name: 'Unemployed',
  data() {
    return {
      submitFlag: true,
      paydayList: [],
      dataForm: {
        // unemployment_cycle: '' // 无业周期
      }
    }
  },
  mounted() {
    this.$emit('submitFlag', true)
  },
  methods: {
    submit() {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
